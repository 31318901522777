<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="10" size="35" ref="toppane">
				<div>
					<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
						:options.sync="options" :server-items-length="totalItems"
						:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
						:loading="loading" loading-text="加载中..." show-select single-select>
						<template v-slot:top>
							<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px" ref="tabletopbar">
								<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
									<v-btn>未计费</v-btn>
									<v-btn>已计费</v-btn>
								</v-btn-toggle>
								<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
									transition="scale-transition" offset-y min-width="290px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="pdate1" label="预约术前日期起" readonly v-bind="attrs"
											v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
									</template>
									<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
								</v-menu>
								<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
									transition="scale-transition" offset-y min-width="290px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="pdate2" label="预约术前日期止" readonly v-bind="attrs"
											v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
									</template>
									<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
								</v-menu>
								<v-text-field v-model="pname" label="客户姓名" clearable dense hide-details style="flex: 0 1 auto;max-width:150px;"></v-text-field>
								<v-btn color="primary" @click.stop="fetchData">查询</v-btn>
							</div>
							<v-divider/>
						</template>
						<template v-slot:item.insurance="{ item }">
							<span :class="item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
						</template>
						<template v-slot:item.apntBldDate="{ item }">
							{{formatTime(item.apntBldDate)}}
						</template>
						<template v-slot:item.progress.preoperCharge="{ item }">
							{{formatTime(item.progress.preoperCharge)}}
						</template>
						<template v-slot:item.opts="{ item }">
							{{getOptsStr(item)}}
						</template>
					</v-data-table>
				</div>
			</pane>
			<pane min-size="20" size="65" v-if="selected.length > 0">
				<div style="max-height: 100%" class="d-flex overflow-y-auto">
					<v-form class="pt-4 pl-4">
						<v-text-field label="检查费" type="number" hide-spin-buttons v-model.number="preoperCharge.检查费"/>
						<v-text-field label="加急费" type="number" hide-spin-buttons v-model.number="preoperCharge.加急费"/>
						<v-text-field label="爽约费" type="number" hide-spin-buttons v-model.number="preoperCharge.爽约费"/>
						<v-btn color="primary" @click.stop="save">保存</v-btn>
					</v-form>
				</div>
			</pane>
		</splitpanes>
	</div>
	<div v-else>
		无此权限
	</div>
</template>

<script>
    import {formatTime, formatDate} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'预约术前时间', value:'apntBldDate', width:180},
                    {text:'术前计费时间', value:'progress.preoperCharge', width:180},
                    {text:'术前费用合计', value:'preoperChargeSum', width:180},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				filter: 0,
				options: {},
				totalItems: 0,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				pname: '',
				tableheight: undefined,
				preoperCharge: {},
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege(['华三术前']);
			if (!this.authed) return;
			this.formatTime = formatTime;
			this.pdate1 = formatDate(Date.now());
			this.pdate2 = formatDate(Date.now());
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO + (item.opts.TX ? '/' + item.opts.TX : '');
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filters = [
					{'progress.preoperCharge':_.exists(false)},
					{'progress.preoperCharge':_.exists(true)},
				];
				const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
				const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
				let filter = [];
				if (t1 && t2) {
					filter.push({apntBldDate:_.gt(t1).lt(t2)});
				} else if (this.pdate1) {
					filter.push({apntBldDate:_.gt(t1)});
				} else if (this.pdate2) {
					filter.push({apntBldDate:_.lt(t2)});
				} else {
					filter.push({apntBldDate:_.exists(true)});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async save() {
				const values = Object.values(this.preoperCharge);
				if (values.every(x => (x === undefined)||(x === null))) {
					this.$dialog.message.error('不能保存空内容');
					return;
				}
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'savePreoperCharge',
						data: {
							id,
							preoperCharge: this.preoperCharge,
						}
					}});
					this.$dialog.message.success('保存完成');
					await this.fetchData();
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
                if (newitem.length === 0) return;
				this.preoperCharge = newitem[0].preoperCharge || this.$options.data().preoperCharge;
			}
		},
        components: { Splitpanes, Pane }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-left: 16px;
}
</style>